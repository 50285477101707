import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import React from 'react';

import MenuContent from './MenuContent.jsx';

function SideMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <React.Fragment>
      <HStack d={{ md: 'none' }}>
        <Menu>
          <MenuButton
            position="fixed"
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            onClick={onOpen}
            top="13px"
            left="20px"
            bg="color.Background"
            color="color.buttonGreen"
            fontSize="20px"
            _hover={{
              background: 'color.buttonGreenHover',
              color: '#fff',
            }}
            _active={{
              background: 'color.buttonGreenHover',
              color: '#fff',
            }}
            zIndex="2"
          />
        </Menu>
        <Drawer placement={'left'} onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody>
              <MenuContent onClose={onClose} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </HStack>
      <HStack d={{ base: 'none', md: 'block' }}>
        <MenuContent onClose={() => false} />
      </HStack>
    </React.Fragment>
  );
}

export default SideMenu;
