import React, { Fragment, useRef } from 'react';
import { Routes, Route } from 'react-router-dom';

import './App.scss';

import Login from './components/features/Login/Login.jsx';
import LoginError from './components/features/Login/LoginError.jsx';
import LoginSuccess from './components/features/Login/LoginSuccess.jsx';
import AllEmployees from './components/Layout/AllEmployees/AllEmployees.jsx';
import AsideMenu from './components/Layout/AsideMenu/AsideMenu.jsx';
import EmployeeDetails from './components/Layout/EmployeeDetails/EmployeeDetails.jsx';
import MainContent from './components/Layout/MainContent/MainContent.jsx';
// import MyProfile from './components/Layout/MyProfile/MyProfile.jsx';
import TopBar from './components/Layout/Topbar/TopBar.jsx';
import ProtectedRoute from './components/protectedRoute/ProtectedRoute.jsx';

import NoPage from 'components/features/NoPage/NoPage';
import AddRole from 'components/Layout/AddRole/AddRole';
import Projects from 'components/Layout/Projects/Projects.jsx';
import SystemAdmin from 'components/Layout/SystemAdmin/SystemAdmin';
import { useAuthContext } from 'context/AuthContext.jsx';

function App() {
  const { isUserLoggedIn, currentUser } = useAuthContext();
  const mainContentRef = useRef();

  return (
    <Fragment>
      {isUserLoggedIn && <TopBar />}
      {isUserLoggedIn && <AsideMenu />}
      <MainContent ref={mainContentRef}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login/success" element={<LoginSuccess />} />
          <Route path="/login/error" element={<LoginError />} />
          <Route
            path="/all-employees"
            element={
              <ProtectedRoute>
                <AllEmployees
                  currentPmId={currentUser?.pmId}
                  employeesType={'all'}
                  mainContentRef={mainContentRef}
                />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="/all-employees">
            <Route path=":employeeId/*" element={<EmployeeDetails />} />
          </Route>
          <Route
            path="/my-employees"
            element={
              <ProtectedRoute>
                <AllEmployees
                  currentPmId={currentUser?.pmId}
                  employeesType={'my'}
                  mainContentRef={mainContentRef}
                />
              </ProtectedRoute>
            }
          />
          <Route path="/my-employees">
            <Route path=":employeeId" element={<EmployeeDetails />} />
          </Route>
          <Route
            path="/projects"
            element={
              <ProtectedRoute>
                <Projects />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/my-profile"
            element={
              <ProtectedRoute>
                <MyProfile />
              </ProtectedRoute>
            }
          /> */}

          <Route
            path="/system-admin"
            element={
              <ProtectedRoute>
                <SystemAdmin />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/add-role"
            element={
              <ProtectedRoute>
                <AddRole />
              </ProtectedRoute>
            }
          ></Route>

          <Route path="*" element={<NoPage />} />
        </Routes>
      </MainContent>
    </Fragment>
  );
}

export default App;
