/* eslint-disable no-unused-vars */

import { Flex, Stack, Text, Button, Grid, Textarea, Input, FormControl } from '@chakra-ui/react';
import moment from 'moment';
import { Paginator } from 'primereact/paginator';
import { useState, useEffect } from 'react';

import { API_URL } from 'config/config';
import { useAuthContext } from 'context/AuthContext';
import axiosInstance from 'services/http';
import { useWindowSize } from 'utils/useWindowSize';

const EmployeeNotes = ({ result, setTrigger, employeeId, loading }) => {
  const size = useWindowSize();
  const { currentUser } = useAuthContext();
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(2);
  const [notes, setNotes] = useState(result.notes);
  const [paginatedNotes, setPaginatedNotes] = useState(notes.slice(0, basicRows));

  useEffect(() => {
    if (size.width > 700) {
      setBasicRows(4);
    } else {
      setBasicRows(2);
    }
  }, [size.width]);

  useEffect(() => {
    setNotes(result.notes);
  }, [loading, result.notes]);

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setPaginatedNotes(notes.slice(event.first, event.first + event.rows));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const note = { subject, text, employeeId: employeeId };
    try {
      const response = await axiosInstance.post(`${API_URL}/note/`, {
        subject: note.subject,
        text: note.text,
        employeeId: note.employeeId,
      });
      setTrigger((curr) => !curr);
      setText('');
      setSubject('');
    } catch (error) {
      console.error(`${error.message}, 💥🤯`);
    }
  };
  const deleteNote = async (noteUid) => {
    try {
      const response = await axiosInstance.delete(`${API_URL}/note/${noteUid}`);
      setTrigger((curr) => !curr);
    } catch (error) {
      console.error(`${error.message}, 💥🤯`);
    }
  };
  return (
    <div className="employee-notes">
      <Stack py="10px">
        <Flex mb="8px" wrap={{ base: 'wrap' }}>
          {currentUser.role === 'project-manager' ? (
            <form onSubmit={handleSubmit}>
              <FormControl>
                <Input
                  mb="3px"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  maxLength="50"
                  fontSize={{ base: '14px', md: '16px' }}
                ></Input>
              </FormControl>
              <FormControl>
                <Textarea
                  mb="3px"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  rows="5"
                  maxLength="300"
                  fontSize={{ base: '12px', md: '14px' }}
                ></Textarea>
              </FormControl>
              <Button ml="5px" type="submit" fontSize={{ base: '10px', md: '11px' }}>
                Add new note
              </Button>
            </form>
          ) : null}
        </Flex>
        <Grid
          gap="1rem"
          gridTemplateColumns="repeat(auto-fit, minmax(227px, 1fr))"
          overflow="auto"
          height={{ base: '310px' }}
        >
          {paginatedNotes.map((note) => {
            return (
              <Stack
                key={note.id}
                border="0.5px solid rgba(32,192,34,0.16)"
                borderRadius="5px"
                padding="10px"
                mb={{ base: '20px', md: '10px' }}
                position="relative"
                backgroundColor="#C6F6D5"
                height="130px"
              >
                <Text fontWeight="bold" fontSize="15px">
                  {note.subject}
                </Text>
                <Text
                  fontStyle="italic"
                  fontSize="15px"
                  marginTop="10px"
                  height="50px"
                  overflowY="auto"
                >
                  {note.text}
                </Text>
                <Flex justifyContent="space-between" fontSize="13px">
                  <Text fontSize={{ base: '10px' }}>{note.projectManagerName}</Text>

                  <Text fontSize={{ base: '10px' }}>
                    {moment(note.createdAt).utc().format('MMMM Do YYYY, h:mm:ss a')}
                  </Text>
                </Flex>
                {currentUser.role === 'project-manager' && (
                  <Button
                    position="absolute"
                    top="3px"
                    right="3px"
                    onClick={() => deleteNote(note.uid)}
                  >
                    X
                  </Button>
                )}
              </Stack>
            );
          })}
        </Grid>
        <Paginator
          first={basicFirst}
          rows={basicRows}
          totalRecords={notes.length}
          rowsPerPageOptions={false}
          onPageChange={onBasicPageChange}
        ></Paginator>
      </Stack>
    </div>
  );
};

export default EmployeeNotes;
