import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '320px',
  smmd: '450px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
});

const theme = extendTheme({
  zIndices: {
    tooltip: '0',
  },
  shadows: {
    purple: '0 0 0 3px rgba(159, 122, 234, 0.6)',
  },
  colors: {
    color: {
      background: '#2f3c4b',
      backgroundLight: '#293744',
      mainSectionBackgroundColor: '#25323d',
      shadowBgLight: '0 16px 30px -10px rgba(70,96,187,0)',
      whiteText: '#fff',
      buttonGreen: '#0e7c2b',
      buttonGreenHover: '#1cba46',
      cardBg: '#1d2733',
    },
  },
  fonts: {
    heading: 'sans-serif',
    body: 'sans-serif',
  },
  breakpoints,
});

export default theme;
