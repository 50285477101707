import { Heading } from '@chakra-ui/react';
import { useEffect } from 'react';

function LoginError() {
  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, 2000);
  }, []);
  return (
    <Heading style={{ textAlign: 'center' }}>
      Error logging in! You must login with @quantox email!
    </Heading>
  );
}

export default LoginError;
