/** @jsxImportSource @emotion/react */
import { useTheme } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useState } from 'react';

import FilterItem from './FilterItem';

function Filter({ filterName, parameters, setFiltersParams }) {
  const [filtersOpened, setFiltersOpened] = useState(false);
  const chakraTheme = useTheme();

  const filtersArr = Object.keys(parameters).map((key) => [key, parameters[key], filterName]);

  function handleClickFilter(el) {
    if (
      el.classList.contains('employees__filter--' + filterName.toLowerCase()) ||
      el.classList.contains('inv-clickable')
    ) {
      const filterEl = document.querySelector('.employees__filter--' + filterName.toLowerCase());
      if (!filtersOpened) filterEl.classList.add('employees__filter--active');
      else filterEl.classList.remove('employees__filter--active');
      setFiltersOpened(!filtersOpened);
    }
  }

  function handleHoverFilter(hovering) {
    const filterEl = document.querySelector('.employees__filter--' + filterName.toLowerCase());
    if (hovering) filterEl.classList.add('employees__filter--active');
    else filterEl.classList.remove('employees__filter--active');
  }

  return (
    <div
      className={'employees__filter ' + 'employees__filter--' + filterName.toLowerCase()}
      onClick={(e) => handleClickFilter(e.target)}
      onMouseEnter={() => handleHoverFilter(true)}
      onMouseLeave={() => handleHoverFilter(false)}
    >
      <h4 className="inv-clickable">Filter by {filterName.replace(/id/gi, '').toUpperCase()}</h4>
      <svg className="inv-clickable" width="11" height="7" xmlns="http://www.w3.org/2000/svg">
        <path
          className="inv-clickable"
          d="M1 1l4.228 4.228L9.456 1"
          stroke="#7C5DFA"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
        />
      </svg>
      <div
        className="employees__filter-menu"
        css={css`
          display: none;
          flex-direction: column;
          position: absolute;
          background-color: ${chakraTheme.colors.color.backgroundLight};
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          min-width: 200px;
          width: auto;
          border-radius: 5px;
          box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.411);
          padding: 15px;
          z-index: 5;
        `}
      >
        {filtersArr.map(([filterName, filterStatus, filter]) => {
          return (
            <FilterItem
              key={filterName}
              setFiltersParams={setFiltersParams}
              filterName={filterName}
              filterStatus={filterStatus}
              filter={filter}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Filter;
