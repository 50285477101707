/** @jsxImportSource @emotion/react */
import './LoadingSpinner.css';
import { Box } from '@chakra-ui/react';

function LoadingSpinner({ color = '#fff' }) {
  return (
    <Box className="lds-ripple" d="block" margin={'auto'} marginTop={'100px'}>
      <Box border={`4px solid ${color}`} borderColor={'color.fontColor'}></Box>
      <Box border={`4px solid ${color}`} borderColor={'color.fontColor'}></Box>
    </Box>
  );
}

export default LoadingSpinner;
