/* eslint-disable no-unused-vars */
import {
  Box,
  Flex,
  Input,
  Text,
  Button,
  Tooltip,
  Avatar,
  AvatarGroup,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import EmployeeOverview from '../EmployeeOverview/EmployeeOverview.jsx';

import ModalBox from 'components/features/Modal/ModalBox.jsx';
import { LoadingPage } from 'components/LoadingPage/LoadingPage';
import { API_URL } from 'config/config.js';
import { useAuthContext } from 'context/AuthContext';
import axiosInstance from 'services/http';
import { getProjects } from 'services/projects';

const Projects = () => {
  const { currentUser } = useAuthContext();
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const {
    isOpen: isAddProjectOpen,
    onOpen: onAddProjectOpen,
    onClose: onAddProjectClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onConfirmDeleteOpen,
    onClose: onConfirmDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmChangeOpen,
    onOpen: onConfirmChangeOpen,
    onClose: onConfirmChangeClose,
  } = useDisclosure();
  const [employeeId, setEmployeeId] = useState('');
  const [myProjects, setMyProjects] = useState(false);
  const [filterTrigger, setFilterTrigger] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [newProjectName, setNewProjectName] = useState('');
  const {
    isOpen: isEmployeeModalOpen,
    onOpen: onEmployeeModalOpen,
    onClose: onEmployeeModalClose,
  } = useDisclosure();

  async function getAllProjects() {
    const allProjects = await getProjects();
    const sortProjects = allProjects.sort(function (a, b) {
      const nameA = a.project.toUpperCase();
      const nameB = b.project.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setProjects(sortProjects);
    setFilteredProjects(sortProjects);
    setIsLoading(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post(`${API_URL}/project/`, {
        project: projectName,
        projectManagerUid: currentUser?.uid,
      });
      getAllProjects();
      onAddProjectClose();
      setProjectName('');
    } catch (error) {
      console.error(`${error.message}, 💥🤯`);
    }
  };

  const handleChange = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(`${API_URL}/project/${projectName}`, {
        project: newProjectName,
      });
      getAllProjects();
      onAddProjectClose();
      setProjectName('');
      onConfirmChangeClose();
    } catch (error) {
      console.error(`${error.message}, 💥🤯`);
    } finally {
      setProjectName('');
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axiosInstance.delete(`${API_URL}/project/${projectName}`);
      getAllProjects();
      onConfirmDeleteClose();
    } catch (error) {
      console.error(`${error.message}, 💥🤯`);
    } finally {
      setProjectName('');
    }
  };

  useEffect(() => {
    if (filterTrigger) {
      setFilteredProjects((curr) =>
        curr.filter((project) => project.projectManager?.uid === currentUser?.uid)
      );
    } else {
      setFilteredProjects(projects);
    }
  }, [filterTrigger, currentUser.uid, projects]);

  useEffect(() => {
    getAllProjects();
  }, []);

  useEffect(() => {
    const filtProjects = projects.filter((project) => {
      return project.project.toLowerCase().includes(searchQuery);
    });
    setFilteredProjects(filtProjects);
  }, [searchQuery]);

  const filterFunction = (event) => {
    setSearchQuery(event.target.value.trim().toLowerCase());
  };

  const fetchEmployeesAsync = () => {
    console.log('fetchEmployeesAsync fake fetchEmployeesAsync!!');
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Box margin={'auto'} maxWidth={'1000px'} width={'90%'}>
        <Box
          width={{ base: '100%', md: '70%', lg: '100%' }}
          top={{ base: '50px', md: '60px' }}
          position="fixed"
          left={{ base: 0, md: 'unset' }}
          background="#25323d"
          paddingTop={{ base: '15px', md: '40px' }}
          pl={{ base: '10px', md: '0px' }}
          zIndex="1"
          mr={{ md: '40px' }}
        >
          <Text fontWeight="bold" textTransform="capitalize" color="#fff" fontSize="20px">
            Projects
          </Text>
          <Flex
            justifyContent={{ base: 'center', md: 'flex-start' }}
            marginBottom={'30px'}
            m="15px"
            ml="0"
            flexWrap={{ base: 'wrap' }}
          >
            <Input
              minWidth={'250px'}
              placeholder={'Find Project'}
              onChange={filterFunction}
              height={'30px'}
              borderColor="none"
              border={'1px solid #435354'}
              focusBorderColor={'1px solid #435354'}
              width={{ base: '100%', md: '300px' }}
              mb={{ base: '10px', md: '0' }}
            />
            {currentUser?.role === 'project-manager' && (
              <Button
                height="30px"
                variant="outline"
                fontSize="14px"
                borderColor="none"
                border={'1px solid #435354'}
                focusBorderColor={'1px solid #435354'}
                flex={{ base: '0 0 110px' }}
                ml="22px"
                minW="110px"
                mb={{ base: '10px', md: '18px' }}
                _hover={{
                  background: '#fff',
                  color: '#000',
                }}
                _active={{
                  background: '#fff',
                  color: '#000',
                }}
                onClick={() => {
                  setFilterTrigger((curr) => !curr);
                }}
              >
                {filterTrigger ? 'All Projects' : 'My Project(s)'}
              </Button>
            )}
            {currentUser.role === 'project-manager' && (
              <Button
                height="30px"
                bg="color.buttonGreen"
                color="#fff"
                fontSize="14px"
                ml="22px"
                minW="150px"
                _hover={{
                  background: '#fff',
                  color: '#000',
                }}
                _active={{
                  background: '#fff',
                  color: '#000',
                }}
                onClick={onAddProjectOpen}
              >
                Create new Project
              </Button>
            )}

            <ModalBox isOpen={isEmployeeModalOpen} onClose={onEmployeeModalClose} showClose>
              <EmployeeOverview fetchEmployeesAsync={fetchEmployeesAsync} employeeId={employeeId} />
            </ModalBox>
            <ModalBox isOpen={isAddProjectOpen} onClose={onAddProjectClose}>
              <form onSubmit={handleSubmit}>
                <Text fontSize={{ base: '14px', md: '18px' }} mb="15px">
                  Please enter your project name
                </Text>
                <Input
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  mb="10px"
                  fontSize={{ base: '11px', md: '14px' }}
                  height={{ base: '22px', md: '35px' }}
                />
                <Button
                  type="submit"
                  background="#0e7c2b"
                  color="#fff"
                  _hover={{
                    background: '#11b13b',
                    color: '#fff',
                  }}
                  _active={{
                    background: '#11b13b',
                    color: '#fff',
                  }}
                  fontSize={{ base: '10px', md: '12px' }}
                  mr="10px"
                >
                  Confirm
                </Button>
                <Button
                  onClick={onAddProjectClose}
                  background="#ff1744"
                  color="#fff"
                  _hover={{
                    background: '#e9496c',
                    color: '#fff',
                  }}
                  _active={{
                    background: '#e9496c',
                    color: '#fff',
                  }}
                  fontSize={{ base: '10px', md: '12px' }}
                >
                  Cancel
                </Button>
              </form>
            </ModalBox>
          </Flex>
        </Box>
        <Flex wrap="wrap" justifyContent="center" marginTop={{ base: '135px' }} pos="relative">
          {filteredProjects.map((project, id) => {
            return (
              <Flex
                key={id}
                borderRadius={'10px'}
                border={'1px solid #435354'}
                width="200px"
                m="15px"
                background="color.cardBg"
                p="11px"
                pos="relative"
                onClick={() => {
                  currentUser.role === 'project-manager' &&
                    currentUser.uid === project?.projectManager?.uid &&
                    onConfirmChangeOpen();
                  setProjectName(project.slug);
                  setNewProjectName(project.project);
                }}
              >
                <Box>
                  <Tooltip label={project.title} p="5px" fontSize="14px" maxW="200px">
                    <Box
                      fontSize={'16px'}
                      padding={'10px'}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      display="-webkit-box"
                      lineHeight="23px"
                      height="83px"
                      css={{
                        WebkitLineClamp: '3',
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      <Text wordBreak="break-word">{project.project}</Text>
                      <AvatarGroup max={2} position="absolute" bottom="-15px" right="0px">
                        {project.employees.map((person, id) => {
                          return (
                            <button
                              key={person.email}
                              onClick={(e) => {
                                e.stopPropagation();
                                setEmployeeId(
                                  person.email.substring(0, person.email.lastIndexOf('@'))
                                );
                                onEmployeeModalOpen();
                              }}
                            >
                              <Avatar
                                name={`${person.name} ${person.lastName}`}
                                src={`https://bit.ly/${person.name}-${person.lastName}`}
                              />
                            </button>
                          );
                        })}
                      </AvatarGroup>
                    </Box>
                  </Tooltip>
                </Box>
                {currentUser.role === 'project-manager' &&
                  currentUser.uid === project?.projectManager?.uid && (
                    <Button
                      pos="absolute"
                      top="7px"
                      right="7px"
                      onClick={(e) => {
                        e.stopPropagation();
                        setProjectName(project.slug);
                        onConfirmDeleteOpen();
                      }}
                      background="transparent"
                      fontSize="14px"
                      _hover={{
                        background: '#ff1744',
                      }}
                      _active={{
                        background: '#ff1744',
                      }}
                    >
                      X
                    </Button>
                  )}
              </Flex>
            );
          })}
          <ModalBox isOpen={isConfirmDeleteOpen} onClose={onConfirmDeleteClose}>
            <div>
              <Text fontSize={{ base: '14px', md: '18px' }} mb="18px">
                Are you sure you want to delete this project?
              </Text>
              <Flex>
                <Button
                  onClick={() => {
                    handleDelete();
                  }}
                  background="#ff1744"
                  color="#fff"
                  _hover={{
                    background: '#e9496c',
                    color: '#fff',
                  }}
                  _active={{
                    background: '#e9496c',
                    color: '#fff',
                  }}
                  mr="10px"
                  fontSize={{ base: '10px', md: '12px' }}
                >
                  Delete
                </Button>
                <Button
                  onClick={onConfirmDeleteClose}
                  background="#0e7c2b"
                  color="#fff"
                  _hover={{
                    background: '#11b13b',
                    color: '#fff',
                  }}
                  _active={{
                    background: '#11b13b',
                    color: '#fff',
                  }}
                  fontSize={{ base: '10px', md: '12px' }}
                >
                  Cancel
                </Button>
              </Flex>
            </div>
          </ModalBox>

          <ModalBox isOpen={isConfirmChangeOpen} onClose={onConfirmChangeClose}>
            <>
              <Text fontSize={{ base: '14px', md: '18px' }} mb="15px">
                Edit your project name
              </Text>
              <form onSubmit={handleChange}>
                <Input
                  value={newProjectName}
                  onChange={(e) => setNewProjectName(e.target.value)}
                  mb="10px"
                  fontSize={{ base: '11px', md: '14px' }}
                  height={{ base: '22px', md: '35px' }}
                />
                <Flex>
                  <Button
                    type="submit"
                    background="#0e7c2b"
                    color="#fff"
                    _hover={{
                      background: '#11b13b',
                      color: '#fff',
                    }}
                    _active={{
                      background: '#11b13b',
                      color: '#fff',
                    }}
                    fontSize={{ base: '10px', md: '12px' }}
                    mr="10px"
                  >
                    Confirm
                  </Button>
                  <Button
                    onClick={onConfirmChangeClose}
                    background="#ff1744"
                    color="#fff"
                    _hover={{
                      background: '#e9496c',
                      color: '#fff',
                    }}
                    _active={{
                      background: '#e9496c',
                      color: '#fff',
                    }}
                    fontSize={{ base: '10px', md: '12px' }}
                  >
                    Cancel
                  </Button>
                </Flex>
              </form>
            </>
          </ModalBox>
        </Flex>
      </Box>
    </>
  );
};

export default Projects;
