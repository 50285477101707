import axiosInstance from './http';

import { API_URL } from 'config/config';

export const getProjects = async () => {
  try {
    const response = await axiosInstance.get(`${API_URL}/project`);
    console.log(response, 'mjau');
    return response.data;
  } catch (err) {
    console.error(`${err.message}`);
  }
};
