import { Image, Text, Stack, Icon } from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
// import { AiOutlineTeam } from 'react-icons/ai';
// import { MdOutlinePending } from 'react-icons/md';
// import { RiQuestionnaireLine } from 'react-icons/ri';
//import { GrUserAdmin } from 'react-icons/gr';
import { Link, useLocation } from 'react-router-dom';

import MenuLink from './MenuLink';
import MenuWrap from './MenuWrap';
//import { RiQuestionnaireLine, RiProfileLine } from 'react-icons/ri';
function MenuItems({ onClose, menuType, employeeRoleStatus }) {
  const itemsRef = useRef([]);
  const [activeLink, setActiveLink] = useState(0);
  let menuItems = [];
  const location = useLocation();

  useEffect(() => {
    // runs on location, i.e. route, change
    if (menuItems?.length) {
      menuItems.forEach((item, index) => {
        if (item.href === location.pathname) setActiveLink(index);
      });
    }
  }, [location, menuItems]);
  switch (employeeRoleStatus) {
    case 'system-admin':
      menuItems = [
        {
          href: '/all-employees',
          // icon: MdOutlinePending,
          src: '/Icons/team.png',
          text: 'All Employees',
        },
        {
          href: '/projects',
          // icon: RiQuestionnaireLine,
          src: '/Icons/project-launch.png',
          text: 'Projects',
        },
        {
          href: '/system-admin',
          src: '/Icons/admin.png',
          text: 'Controls',
        },
        {
          href: '/add-role',
          src: '/Icons/leader.png',
          text: 'Add Role',
        },
      ];
      break;
    case 'project-manager':
      menuItems = [
        {
          href: '/all-employees',
          // icon: MdOutlinePending,
          src: '/Icons/team.png',
          text: 'All Employees',
        },
        {
          href: '/my-employees',
          // icon: AiOutlineTeam,
          src: '/Icons/teamwork.png',
          text: 'My Employees',
        },
        {
          href: '/projects',
          // icon: RiQuestionnaireLine,
          src: '/Icons/project-launch.png',
          text: 'Projects',
        },
        // {
        //   href: '/my-profile',
        //   icon: RiProfileLine,
        //   text: 'My Profile',
        // },
      ];
      break;
  }

  return (
    <MenuWrap menuType={menuType}>
      {menuItems?.length &&
        menuItems.map(({ text, src = null, href, icon = null }, i) => (
          <Link to={href} key={i} onClick={() => onClose()}>
            <MenuLink
              menuType={menuType}
              innerRef={(el) => (itemsRef.current[i] = el)}
              active={i == activeLink ? true : false}
              marginBottom="10px"
            >
              {' '}
              <Stack
                flex="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {src && <Image src={src} width="25px"></Image>}
                <Text fontSize="2xl" padding="9px">
                  {icon && <Icon as={icon} mr="1rem" mt=".2rem"></Icon>}

                  {text}
                </Text>
              </Stack>
            </MenuLink>
          </Link>
        ))}
    </MenuWrap>
  );
}

export default MenuItems;
