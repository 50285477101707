/* eslint-disable no-unused-vars */
import { Tab, Tabs, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import './EmployeeOverview.css';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import EmployeeInfo from './EmployeeInfo';
import EmployeeNotes from './EmployeeNotes';
import EmployeeProjects from './EmployeeProjects';

import { LoadingPage } from 'components/LoadingPage/LoadingPage';
import { API_URL } from 'config/config';
import { useAuthContext } from 'context/AuthContext';
import axiosInstance from 'services/http';

const EmployeeOverview = ({ employeeId, fetchEmployeesAsync }) => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const { currentUser } = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`${API_URL}/employee/${employeeId}`);
        setResult(response.data);
        console.log(response, 'Jedan');
        console.log(response.data, 'Dva');
      } catch (err) {
        console.error(`${err.message}`);
        console.log('Nema response-a');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [trigger, employeeId]);
  return (
    <div
      className={clsx('employee-overview', currentUser.role !== 'project-manager' && 'not-manager')}
    >
      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList>
          <Tab fontSize="15px">Info</Tab>
          <Tab fontSize="15px">Projects</Tab>
          <Tab fontSize="15px">Notes</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>{loading ? <LoadingPage /> : <EmployeeInfo result={result} />}</TabPanel>
          <TabPanel>
            {loading ? (
              <LoadingPage />
            ) : (
              <EmployeeProjects
                employeeId={employeeId}
                result={result}
                setTrigger={setTrigger}
                fetchEmployeesAsync={fetchEmployeesAsync}
              />
            )}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <LoadingPage />
            ) : (
              <EmployeeNotes
                result={result}
                setTrigger={setTrigger}
                employeeId={employeeId}
                loading={loading}
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default EmployeeOverview;
