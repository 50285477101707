import { Heading } from '@chakra-ui/react';
import { useEffect } from 'react';

function NoPage() {
  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, 1000);
  }, []);
  return <Heading style={{ textAlign: 'center' }}>There is no page for this route!!</Heading>;
}

export default NoPage;
