import { Box, Center } from '@chakra-ui/react';

import loadingImage from './assets/kuce-loader.gif';

export const LoadingPage = () => {
  return (
    <Center>
      <Box mx="auto">
        <img src={loadingImage} alt="Loading..." /* width={'800px'} */ />
      </Box>
    </Center>
  );
};
