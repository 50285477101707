import { Text, Heading, Flex, Image, Center } from '@chakra-ui/react';

const EmployeeInfo = ({ result }) => {
  return (
    <div className="employee-info">
      <Center mb="10px">
        <Image
          src={`https://ui-avatars.com/api/?name=${result?.name}+${result?.lastName}&rounded=true&background=random`}
        />
      </Center>
      <Heading fontSize={{ b: '20px', md: '28px' }}>
        {!result.name ? 'Name' : result.name} {!result.lastName ? 'Lastname' : result.lastName}
      </Heading>
      <Flex justifyContent="center" fontSize={{ b: '18px', md: '20px' }}>
        <Text mr="2px">{!result.cityId ? 'City' : result.cityId},</Text>
        <Text>{!result.countryId ? 'Country' : result.countryId}</Text>
      </Flex>
      <Flex justifyContent="center" fontSize={{ b: '18px', md: '20px' }}>
        <Text fontWeight="bold" mr="4px">
          Seniority:
        </Text>
        <Text>{!result.seniorityId ? 'No seniority' : result.seniorityId}</Text>
      </Flex>
      <Flex fontWeight="semibold" fontSize={{ b: '18px', md: '20px' }} justifyContent="center">
        <Text fontWeight="bold" mr="4px">
          Technologies:
        </Text>
        {result.technologies.length > 0
          ? result.technologies.map((technology, index) => {
              return (
                <span className="employee-technology" key={technology.slug}>
                  {index > 0 && ','} {technology.technology}
                </span>
              );
            })
          : 'None'}
      </Flex>
    </div>
  );
};

export default EmployeeInfo;
