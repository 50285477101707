import { Heading } from '@chakra-ui/react';
import { useEffect } from 'react';

import { useAuthContext } from 'context/AuthContext.jsx';
import axiosInstance from 'services/http';

function LoginSuccess() {
  const { handleLogin } = useAuthContext();

  useEffect(() => {
    setTimeout(async () => {
      try {
        let response;
        response = await axiosInstance.get('/auth/user', {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        });
        handleLogin();
        console.log(response.data);
        return response.data;
      } catch (err) {
        console.error(`${err.message}, 💥🤯 LOOOOOOO`, err.response);
        return null;
      }
    }, 200);
  }, []);
  return (
    <Heading style={{ textAlign: 'center' }}>
      You successfully logged in! <button onCclick="self.close()">Close</button>
    </Heading>
  );
}

export default LoginSuccess;
