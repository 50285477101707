import clsx from 'clsx';
import React from 'react';

import { useAuthContext } from 'context/AuthContext.jsx';

const MainContent = React.forwardRef((props, ref) => {
  const { isUserLoggedIn } = useAuthContext();
  return (
    <div ref={ref} className={clsx('section-main', isUserLoggedIn && 'section-main--logged')}>
      {props.children}
    </div>
  );
});

export default MainContent;
