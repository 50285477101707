/* eslint-disable no-unused-vars */
import { EditIcon, DeleteIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Select,
  Input,
  FormControl,
  Text,
  Button,
  Box,
  color,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FcBusinessman } from 'react-icons/fc';
import { useQuery } from 'react-query';

import LoadingSpinner from 'components/features/LoadingSpinner/LoadingSpinner';
import EmployeeForm from 'components/Layout/SystemAdmin/EmployeeForm';
import manageData from 'services/admin';
import { fetchEmployees, getPmName } from 'services/employees';
import axiosInstace from 'services/http';

import './SystemAdmin.css';

// import { getProjects } from 'services/fakeapi';

const SystemAdmin = () => {
  const [selectedValue, setSelectedValue] = useState('');
  const [arrayItems, setArrayItems] = useState([]); // niz objektata - (zemlje, ili gradovi, tehnologije..)
  const [data, setData] = useState('');
  const [editItem, setEditItem] = useState('');
  const [editInput, setEditInput] = useState(false);
  const [oldItem, setOldItem] = useState('');
  const [currentItem, setCurrentItem] = useState();
  const [alertObj, setAlertObj] = useState({
    // isOpen: false,
    changeColor: '',
    text: '',
  });
  const [added, setAdded] = useState(false);
  const [countries, setCountries] = useState([]);
  const [oneCountry, setOneCountry] = useState('');
  const [employeeName, setEmployeeName] = useState('');
  const [employeeSurname, setEmployeeSurname] = useState('');
  const [employeeEmail, setEmployeeEmail] = useState('');
  const [addingEmployee, setAddingEmployee] = useState(false);
  const [editingEmployee, setEditingEmployee] = useState(false);
  const [editingEmployeeEmail, setEditingEmployeeEmail] = useState('');

  console.log(countries);
  const showAlert = (changeColor = '', text = '') => {
    setAlertObj({ changeColor, text });
  };

  console.log(selectedValue, arrayItems, data, editItem, editInput, oldItem, currentItem, added);

  console.log(added);
  // const arrayData = arrayItems.map((item) => item[Object.keys(item)[0]]);
  // console.log(arrayData, 'ffssfafa');

  const {
    isLoading,
    isRefetching: isFetching,
    data: reactQueryEmployeeData,
  } = useQuery('employeesReactQueryCachedData', async () => {
    const employeesData = await fetchEmployees();
    return await Promise.all([
      ...employeesData.map(async (employee) => {
        const pmUid = employee?.project?.projectManager?.user;
        const pmName = await getPmName(pmUid);
        employee['project-manager'] = pmName;
        return employee;
      }),
    ]);
  });

  const getApi = async () => {
    try {
      let response = {};
      if (selectedValue === 'employee') {
        response.data = reactQueryEmployeeData;
      } else {
        response = await axiosInstace.get(`/${selectedValue}`);
      }
      console.log(selectedValue);
      console.log(response.data);
      console.log(arrayItems);
      return setArrayItems(response.data);
    } catch (err) {
      console.error(`${err.message}`);
    }
  };

  useEffect(() => {
    getApi();
    console.log('fsafsafdfdaaaaaaalradiiiiiiii', added);
  }, [selectedValue, added, reactQueryEmployeeData]);

  const changeHandler = async (oldItem) => {
    await manageData.changeData(selectedValue, oldItem, editItem);
    setEditItem('');
    setEditInput(false);
    showAlert('green', `You have successfully changed ${selectedValue}`);
    displayMessage();
  };

  // Za menjenjanje na serveru potrebna su mi tri argumenta OBLAST, STARIITEM, NOVIITEM

  const deleteHandler = async (t) => {
    await manageData.deleteData(t.trim(), selectedValue);
    console.log(t);
    console.log(selectedValue);
    onDeleteClose();
    showAlert('red', `You have successfully deleted ${currentItem}`);
    displayMessage();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data) {
      if (selectedValue === 'city') {
        console.log('to je citi');
        const country2 = selectedValue.countryId;
        console.log(country2, 'zemljaaaaaaaaaaaaaaa');
        manageData.addCity(oneCountry, data);
      }
      await manageData.addData(data, selectedValue); // data je upis -input polje, selectedValue je country, city...
    }
    console.log(selectedValue, 'aaaddddddddddddddddddddddddddddddddddddddddddsdadadsasfdfas');
    setEditInput(false);
    openModal();
    displayMessage();
  };

  const handleSubmitEmployee = (e) => {
    e.preventDefault();
    console.log('uspeo sam');
    console.log(employeeName, employeeSurname, employeeEmail, 'info 592');
    manageData.addEmployee(employeeName, employeeSurname, employeeEmail);
    displayMessage();
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const {
    isOpen: isEmployeeOpen,
    onOpen: onEmployeeOpen,
    onClose: onEmployeeClose,
  } = useDisclosure();

  const openModal = () => {
    onOpen();
  };

  const closeModal = () => {
    onClose();
    setData('');
  };

  // const openModalDelete = () => {
  //   onDeleteOpen();
  // };

  const closeModalDelete = () => {
    onDeleteClose();
  };

  const displayMessage = () => {
    console.log('ddddauuuuuuuuuuuuuubreeeeeeeee');
    showAlert();
    setAdded(!added);
  };

  const openModalEmployee = () => {
    onEmployeeOpen();
  };

  const closeModalEmployee = () => {
    onEmployeeClose();
  };

  async function getCountries() {
    if (selectedValue === 'city') {
      const response = await axiosInstace.get(`/country`);
      console.log(response);
      const countr = response.data;
      console.log(countr);
      const countries = countr.map((item) => item.country);
      setCountries(countries);

      return response;
    }
    // setCountries(response[Object.keys(item)[0]]);
  }
  // getCountries();
  // console.log(con, 'dadada');
  // console.log(countries, 'drzave moje');
  console.log('pre render sysadmin', isLoading, selectedValue);
  return (
    <>
      <Text
        margin={'0 auto'}
        width={'450px'}
        textAlign={'center'}
        marginBottom={'27px'}
        fontSize={'2rem'}
        opacity={'0.7'}
        // textShadow={' 2px 2px 2px rgb(124, 93, 250)'}
        letterSpacing={'3px'}
      >
        Data Management
      </Text>
      <Box
        maxWidth={['500px']}
        margin={'0 auto'}
        // border={'1px solid black'}
        padding={'30px 20px 60px'}
        backgroundColor={'#384c5d'}
        borderRadius={'10px'}
        boxShadow={'0px 0px 14px -1px #12181d'}
      >
        <FormControl>
          <Text
            fontSize={'2.3rem'}
            marginBottom={'10px'}
            opacity={'0.9'}

            // color={'#d9d9d9'}
            // textShadow={'-1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5)'}
          >
            Add
            <ChevronRightIcon w={8} h={8} color={'rgb(124, 93, 250)'} />
            <Text as={'em'}>{selectedValue}</Text>
          </Text>
          <Box display={'flex'}>
            {selectedValue === 'employee' ? (
              <Button
                onClick={() => {
                  setEditingEmployee(false);
                  setAddingEmployee(!addingEmployee);
                  window.scrollTo(0, 200);
                }}
                color={'black'}
                width={'80px'}
                height={'40px'}
                fontSize={'15px'}
              >
                <FcBusinessman size={'30px'} />
              </Button>
            ) : (
              <Input
                onChange={(e) => setData(e.target.value)}
                height={'50px'}
                fontSize={'2rem'}
                value={data}
              />
            )}

            {selectedValue === 'city' ? (
              <Box>
                <Text textAlign={'center'} fontSize={'12px'} marginTop={'-18px'}>
                  Choose country{' '}
                </Text>
                <Select
                  value={oneCountry}
                  onChange={(e) => setOneCountry(e.target.value)}
                  width={'110px'}
                  height={'50px'}
                  fontSize={'15px'}
                >
                  {countries.map((c, id) => (
                    <option key={id} value={c}>
                      {c}
                    </option>
                  ))}
                </Select>
              </Box>
            ) : null}
            {data ? (
              <Button
                onClick={handleSubmit}
                height={'50px'}
                width={'50px'}
                fontSize={'20px'}
                color={'#2f3c4b'}
                transition={'width 2s'}
              >
                +
              </Button>
            ) : null}
          </Box>
        </FormControl>
        <Box marginTop={'10px'}>
          {editInput && (
            <Box display={'flex'}>
              <Input
                onChange={(e) => setEditItem(e.target.value)}
                value={editItem}
                placeholder={'Edit...'}
                height={'36px'}
                fontSize={'1.7rem'}
              />
              <Button
                onClick={() => {
                  changeHandler(oldItem);
                }}
                height={'36px'}
                width={'60px'}
                backgroundColor={'green.400'}
                color={'white'}
                _hover={{
                  background: '#009933',
                }}
              >
                Edit
              </Button>
              <Button
                onClick={() => setEditInput(false)}
                color={'white'}
                height={'36px'}
                backgroundColor={'#ff6666'}
                width={'40px'}
                _hover={{
                  background: '#ff0000',
                }}
              >
                x
              </Button>
            </Box>
          )}
          {alertObj.changeColor === 'green' && (
            <Box>
              <Text fontSize={'15px'} color={'green.400'}>
                {alertObj.text}
              </Text>
            </Box>
          )}
          {alertObj.changeColor === 'red' && (
            <Box>
              <Text fontSize={'15px'} color={'red.400'}>
                {alertObj.text}
              </Text>
            </Box>
          )}
          {console.log(alertObj.changeColor, 'fsdfsfafsasfsadfasfasfasfasfsasfs')}
          <Select
            value={selectedValue}
            onChange={(e) => {
              setSelectedValue(e.target.value);
              setAddingEmployee(false);
              setEditingEmployee(false);
              setEditInput(false);
            }}
            height={'50px'}
            fontSize={'2rem'}
            color={color.background}
            marginTop={'36px'}
            cursor={'pointer'}
            className="select-css"
          >
            {/* <option value="">-</option> */}
            <option value="country" className="select-css">
              Countries
            </option>
            <option value="city">Cities</option>
            <option value="seniority">Seniorities</option>
            <option value="technology">Technologies</option>
            <option value="project">Projects</option>
            <option value="employee">Employees</option>
            {/* <option value="role">PM and System Admin</option> */}
          </Select>
        </Box>
        {addingEmployee && (
          <EmployeeForm setAddingEmployee={setAddingEmployee} setAdded={setAdded} />
        )}
        {editingEmployee && (
          <EmployeeForm
            setEditingEmployee={setEditingEmployee}
            type="editing"
            employeeEmail={editingEmployeeEmail}
            setAdded={setAdded}
          />
        )}
        <Box
          backgroundColor={'white'}
          color={'black'}
          borderRadius={'10px'}
          marginTop={'15px'}
          // p={'10px 10px'}
          style={{
            padding: selectedValue.length === 0 ? null : '10px 10px',
          }}
        >
          {isFetching && selectedValue === 'employee' && <p>We are updating employees data!!</p>}
          {isLoading && selectedValue === 'employee' ? (
            <LoadingSpinner color={'black'} />
          ) : (
            arrayItems.map((item, id) => {
              // items.push(item[Object.keys(item)[0]]);
              return (
                <Box key={id} height={'30px'} className="select-css">
                  <Box
                    fontSize={'2rem'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                    // _hover={{
                    //   background: 'red',
                    //   color: 'blue.500',
                    // }}
                    className="select-css"
                  >
                    {selectedValue === 'employee' ? (
                      <>
                        <span className="select-css">{item['name']}&nbsp;</span>
                        <span className="select-css">{item[Object.keys(item)[2]]}</span>
                      </>
                    ) : (
                      <span className="select-css">{item[Object.keys(item)[0]]}</span>
                    )}

                    <Button
                      onClick={() => {
                        if (selectedValue === 'employee') {
                          setAddingEmployee(false);
                          setEditingEmployee(true);
                          setEditingEmployeeEmail(item.email);
                        } else {
                          setEditItem(item[Object.keys(item)[0]]);
                          setOldItem(item[Object.keys(item)[0]]);
                          setEditInput(true);
                        }
                        console.log('535325325325325325');
                        // nece da skroluje jer nije jos ubacilo formu nekadddddd pa nema gde dole mesta da skrolujeeee!
                        window.scrollTo(0, 200);
                      }}
                      marginLeft={'auto'}
                      color={'black'}
                    >
                      <EditIcon w={8} h={8} color={'green.400'} />
                    </Button>
                    <Button
                      onClick={() =>
                        /* deleteHandler(item[Object.keys(item)[0]])} */
                        setCurrentItem(item[Object.keys(item)[0]], onDeleteOpen())
                      }
                      color={'black'}
                    >
                      <DeleteIcon
                        w={7}
                        h={7}
                        color={'red.400'}
                        _hover={{
                          background: 'white',
                          color: 'blue.500',
                        }}
                      />
                    </Button>
                  </Box>
                </Box>
              );
            })
          )}
        </Box>
        <Modal blockScrollOnMount={true} isOpen={isOpen} size="xl" onClose={closeModal}>
          <ModalOverlay color={'white'} />
          <ModalContent
            marginTop={'130px'}
            color="white"
            backgroundColor={'#476b6b'}
            border="1px"
            borderRadius={'15px'}
            borderColor="white"
            p={'20px 50px 50px 50px'}
          >
            <ModalHeader marginBottom="30px" textAlign={'center'} textTransform={'uppercase'}>
              You add new {selectedValue}{' '}
              <Text as={'em'} fontSize={'20px'}>
                {data}
              </Text>
            </ModalHeader>
            {/* <ModalCloseButton onClick={openModalDelete} /> */}

            <Button
              backgroundColor={'white'}
              onClick={closeModal}
              color={'black'}
              margin={'0 auto'}
              _hover={{
                opacity: '1',
                background: 'white',
                color: 'black',
              }}
            >
              Close
            </Button>
          </ModalContent>
        </Modal>
        <Modal
          blockScrollOnMount={true}
          isOpen={isDeleteOpen}
          size="xl"
          motionPreset="slideInBottom"
          onClose={closeModalDelete}
        >
          <ModalOverlay color={'white'} />
          <ModalContent
            marginTop={'130px'}
            color="white"
            backgroundColor={'#476b6b'}
            border="1px"
            borderRadius={'15px'}
            borderColor="white"
            p={'20px 50px 50px 50px'}
          >
            <ModalHeader
              marginBottom="30px"
              textAlign={'center'}
              textTransform={'uppercase'}
              // width={'380px'}
            >
              <Text>Do you realy want to delete </Text>

              <Text as={'em'} fontSize={'20px'}>
                {currentItem} ?
              </Text>
            </ModalHeader>
            <ModalCloseButton onClick={closeModalDelete} />

            {console.log(currentItem)}
            <Box margin={'0 auto'}>
              <Button
                backgroundColor={'white'}
                onClick={() => deleteHandler(currentItem)}
                color={'black'}
                marginRight={'10px'}
                width={'50px'}
                _hover={{
                  opacity: '0.8',
                  background: 'white',
                  color: 'black',
                }}
              >
                Yes
              </Button>
              <Button
                backgroundColor={'white'}
                onClick={closeModalDelete}
                color={'black'}
                width={'50px'}
                _hover={{
                  opacity: '0.8',
                  background: 'white',
                  color: 'black',
                }}
              >
                No
              </Button>
            </Box>
          </ModalContent>
        </Modal>
        <Modal
          blockScrollOnMount={true}
          isOpen={isEmployeeOpen}
          size="xl"
          motionPreset="slideInBottom"
          onClose={closeModalEmployee}
        >
          <ModalOverlay color={'white'} />
          <ModalContent
            marginTop={'130px'}
            color="white"
            backgroundColor={'#476b6b'}
            border="1px"
            borderRadius={'15px'}
            borderColor="white"
            // p={'20px 50px 50px 50px'}
          >
            <ModalHeader
              marginBottom="30px"
              textAlign={'center'}
              textTransform={'uppercase'}
              // width={'380px'}
            >
              <Text>Add Employee </Text>
            </ModalHeader>
            <ModalCloseButton onClick={closeModalEmployee} />

            {console.log(currentItem)}
            <Box margin={'0 auto'} textAlign={'center'} paddingBottom={'15px'} fontSize={'25px'}>
              {/* <Button
                backgroundColor={'white'}
                onClick={() => {
                  console.log('loooooooooogogogogogogooooooo');
                }}
                color={'black'}
                marginRight={'10px'}
                width={'50px'}
                _hover={{
                  opacity: '0.8',
                  background: 'white',
                  color: 'black',
                }}
              >
                Yes
              </Button>
              <Button
                backgroundColor={'white'}
                onClick={closeModalEmployee}
                color={'black'}
                width={'50px'}
                _hover={{
                  opacity: '0.8',
                  background: 'white',
                  color: 'black',
                }}
              >
                No
              </Button> */}
              <FormControl onSubmit={handleSubmitEmployee}>
                <Input
                  width={'80%'}
                  height={'40px'}
                  placeholder={'Name'}
                  fontSize={'15px'}
                  marginBottom={'10px'}
                  onChange={(e) => setEmployeeName(e.target.value)}
                />

                <Input
                  width={'80%'}
                  height={'40px'}
                  placeholder={'Surname'}
                  fontSize={'15px'}
                  marginBottom={'10px'}
                  onChange={(e) => setEmployeeSurname(e.target.value)}
                />
                <Input
                  width={'80%'}
                  height={'40px'}
                  placeholder={'Mail'}
                  fontSize={'15px'}
                  marginBottom={'10px'}
                  onChange={(e) => setEmployeeEmail(e.target.value)}
                />
                <Box>
                  <Button onClick={handleSubmitEmployee} color={'black'}>
                    Add Employee
                  </Button>
                </Box>
              </FormControl>
            </Box>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default SystemAdmin;
