import {
  Button,
  Select,
  FormControl,
  FormLabel,
  Input,
  Text,
  Box,
  Grid,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import manageData from 'services/admin';

const AddRole = () => {
  const [users, setUsers] = useState([]);
  const [currentItem, setCurrentItem] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentRole, setCurrentRole] = useState('');
  // const [errMessage, setErrorMesssage] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted, isSubmitting, isSubmitSuccessful },
  } = useForm();

  async function getUsers() {
    const response = await manageData.getUser();
    setUsers(response);
    console.log(response);
    return response;
  }

  useEffect(() => {
    getUsers();
  }, []);

  console.log(users);

  const onSubmit = (data) => {
    const email = data.email;
    const role = data.role;
    const name = data.name;
    // const formData = new FormData();
    // formData.append('files', chooseFile);
    // console.log(email, role, chooseFile);
    manageData.addUser(email, role, name /* , chooseFile */);
  };

  useEffect(() => {
    if (isSubmitted /* && !errors.edit */ && isSubmitSuccessful) {
      setTimeout(getUsers, 1000);
    }
  }, [isSubmitted, isSubmitting]);

  const deleteHandler = () => {
    manageData.deleteUser(currentItem);
    setTimeout(getUsers, 1000);
  };

  const editHandler = () => {
    manageData.editUser(currentItem, currentRole);
    setTimeout(getUsers, 1000);
  };

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const closeModalDelete = () => {
    onDeleteClose();
  };

  const closeModalEdit = () => {
    onEditClose();
  };

  // validate email

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleEmailValidation = (email) => {
    console.log('ValidateEmail was called with', email);

    const isValid = isValidEmail(email);

    const validityChanged = (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
      console.log('Fire tracker with', isValid ? 'Valid' : 'Invalid');
    }
    return isValid;
  };

  return (
    <Box
      maxWidth={['920px']}
      margin={'0 auto'}
      padding={'30px 20px 60px'}
      backgroundColor={'#384c5d'}
      borderRadius={'10px'}
      boxShadow={'0px 0px 14px -1px #12181d'}
    >
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            isRequired
            maxWidth={'400px'}
            marginBottom={'20px'}
            backgroundColor={'#27343f'}
            padding={'30px'}
            margin={'0 auto'}
            borderRadius={'10px'}
            boxShadow={' 0px 0px 17px 1px #EBEBEB'}
          >
            <Text fontSize={'20px'} marginBottom={'10px'}>
              Add new Admin or Project Manager
            </Text>
            <FormLabel htmlFor="name">NAME</FormLabel>
            <Input
              id="name"
              height={'30px'}
              fontSize={'13px'}
              {...register('name', {
                required: true,
                validate: (value) => {
                  return !!value.trim();
                },
              })}
              marginBottom={'10px'}
            />
            <FormLabel htmlFor="email">EMAIL</FormLabel>
            <Input
              id="email"
              height={'30px'}
              fontSize={'13px'}
              {...register('email', {
                required: true,
                validate: handleEmailValidation,
              })}
              marginBottom={'10px'}
            />
            {errors.email && (
              <Text color="red" position={'absolute'} /* marginLeft={'70px'} */ right={'30px'}>
                Please enter a valid email
              </Text>
            )}
            {/* <FormLabel htmlFor="file-upload" requiredIndicator={false}>
              Photo
            </FormLabel> */}
            {/* <Input
              id="file-upload"
              type="file"
              onChange={(e) => setChooseFile(e.target.files[0])}
              marginBottom={'10px'}
            /> */}
            <Text marginBottom={'5px'}>SELECT ROLE</Text>
            <Select
              size="lg"
              id="role"
              marginBottom={'15px'}
              fontSize={'14px'}
              {...register('role', {
                required: true,
                validate: (value) => {
                  return !!value.trim();
                },
              })}
            >
              <option value={'project-manager'}>Project Manager</option>
              <option value={'system-admin'}>System Admin</option>
            </Select>
            <Button color={'black'} type={'submit'} fontSize={'13px'}>
              Create
            </Button>
          </FormControl>
        </form>
        <Grid
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
            'repeat(auto-fill,minmax(350px,1fr))',
          ]}
          gap={6}
          py="50px"
          px={['15px', '75px', '75px', '75px']}
        >
          {users.map((user) => {
            return (
              <Box
                key={user.id}
                // backgroundColor={'#fff'}
                backgroundColor={'#f2e6ff'}
                marginBottom={'5px'}
                padding={'10px'}
                fontSize={'14px'}
                borderRadius={'10px'}
                fontFamily={'mono'}
              >
                <Box color={'black'}>
                  <Text>Name: {user.name}</Text>
                  <Text>Email: {user.email}</Text>
                  <Text>Role: {user.roleId}</Text>
                  {/* <img src={user.picture} alt={user.name} width={'50px'} /> */}
                </Box>
                <Box color={'black'}>
                  <Button
                    backgroundColor={'green.400'}
                    color={'white'}
                    _hover={{
                      background: '#009933',
                    }}
                    width={'50px'}
                    marginRight={'5px'}
                    onClick={() => {
                      onEditOpen();
                      setCurrentItem(user.userUuid);
                      setCurrentEmail(user.email);
                      setCurrentRole('project-manager');
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    backgroundColor={'red.400'}
                    color={'white'}
                    _hover={{
                      background: 'red.600',
                    }}
                    width={'50px'}
                    onClick={() => {
                      setCurrentItem(user.userUuid);
                      setCurrentEmail(user.email);
                      onDeleteOpen();
                    }}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            );
          })}
        </Grid>
      </Box>
      <Modal
        blockScrollOnMount={true}
        isOpen={isDeleteOpen}
        size="xl"
        motionPreset="slideInBottom"
        onClose={closeModalDelete}
      >
        <ModalOverlay color={'white'} />
        <ModalContent
          marginTop={'130px'}
          color="white"
          backgroundColor={'#476b6b'}
          border="1px"
          borderRadius={'15px'}
          borderColor="white"
          p={'20px 50px 50px 50px'}
        >
          <ModalHeader
            marginBottom="30px"
            textAlign={'center'}
            textTransform={'uppercase'}
            // width={'380px'}
          >
            <Text>Do you realy want to delete </Text>
            <Text>{currentEmail}</Text>
          </ModalHeader>
          <ModalCloseButton onClick={closeModalDelete} />

          <Box margin={'0 auto'}>
            <Button
              backgroundColor={'white'}
              onClick={() => {
                deleteHandler();
                closeModalDelete();
              }}
              color={'black'}
              marginRight={'10px'}
              width={'50px'}
              _hover={{
                opacity: '0.8',
                background: 'white',
                color: 'black',
              }}
            >
              Yes
            </Button>
            <Button
              backgroundColor={'white'}
              onClick={closeModalDelete}
              color={'black'}
              width={'50px'}
              _hover={{
                opacity: '0.8',
                background: 'white',
                color: 'black',
              }}
            >
              No
            </Button>
          </Box>
        </ModalContent>
      </Modal>
      <Modal
        blockScrollOnMount={true}
        isOpen={isEditOpen}
        size="xl"
        motionPreset="slideInBottom"
        onClose={closeModalEdit}
      >
        <ModalOverlay color={'white'} />
        <ModalContent
          marginTop={'130px'}
          color="white"
          backgroundColor={'#476b6b'}
          border="1px"
          borderRadius={'15px'}
          borderColor="white"
          p={'20px 50px 50px 50px'}
        >
          <ModalHeader
            marginBottom="10px"
            textAlign={'center'}
            textTransform={'uppercase'}
            // width={'380px'}
          >
            <Text>Edit role for</Text>
            <Text marginBottom={'20px'}> {currentEmail}</Text>
            <Select
              size="lg"
              id="role"
              marginBottom={'15px'}
              fontSize={'14px'}
              value={currentRole}
              onChange={(e) => setCurrentRole(e.target.value)}
            >
              <option value={'project-manager'}>Project Manager</option>
              <option value={'system-admin'}>System Admin</option>
            </Select>
          </ModalHeader>
          <ModalCloseButton onClick={closeModalEdit} />

          <Box margin={'0 auto'}>
            <Button
              backgroundColor={'white'}
              onClick={() => {
                editHandler();
                closeModalEdit();
              }}
              color={'black'}
              marginRight={'10px'}
              width={'50px'}
              _hover={{
                opacity: '0.8',
                background: 'white',
                color: 'black',
              }}
            >
              Confirm
            </Button>
            <Button
              backgroundColor={'white'}
              onClick={closeModalEdit}
              color={'black'}
              width={'50px'}
              _hover={{
                opacity: '0.8',
                background: 'white',
                color: 'black',
              }}
            >
              No
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddRole;
