/* eslint-disable no-unused-vars */
import { Button, Stack, Flex, Text, Select } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { API_URL } from 'config/config';
import { useAuthContext } from 'context/AuthContext';
import axiosInstance from 'services/http';

const EmployeeProjects = ({ result, employeeId, setTrigger, fetchEmployeesAsync }) => {
  const [loading, setLoading] = useState(true);
  const [resultProject, setResultProject] = useState(null);
  const { currentUser } = useAuthContext();
  const [selectedValue, setSelectedValue] = useState('');
  const projectsArr =
    resultProject?.project?.map((project) => {
      return project.project;
    }) || [];

  const handleClickAddProject = async () => {
    try {
      const response = await axiosInstance.put(`${API_URL}/employee/${employeeId}`, {
        project: selectedValue,
      });
      fetchEmployeesAsync();
      setTrigger((curr) => !curr);
    } catch (err) {
      console.error('Error:', err);
    }
  };

  const handleClickRemoveProject = async () => {
    try {
      const response = await axiosInstance.put(`${API_URL}/employee/${employeeId}`, {
        project: '',
      });
      fetchEmployeesAsync();
      setTrigger((curr) => !curr);
    } catch (err) {
      console.error('Error:', err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`${API_URL}/projectmanager/${currentUser.uid}`);
        setResultProject(response.data);
      } catch (err) {
        console.error(`${err.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentUser.uid]);

  return (
    <div className="employee-projects">
      <Stack padding="30px">
        {!result.project ? (
          <Stack>
            <Text fontSize={{ b: '18px', md: '22px' }} mb="5px" textAlign="center">
              This user is not currently working on any project.
            </Text>
            {currentUser.role === 'project-manager' && (
              <>
                <Stack padding="5px" paddingInline="12px" borderRadius="5px">
                  <Stack>
                    <Select
                      placeholder="Select option"
                      value={selectedValue}
                      onChange={(e) => setSelectedValue(e.target.value)}
                      minWidth="163px"
                      fontSize={{ base: '10px', md: '14px' }}
                      height={{ base: '24px', md: '40px' }}
                    >
                      {resultProject?.project.map((project) => {
                        return (
                          <option key={project.slug} value={project.project}>
                            {' '}
                            {project.project}
                          </option>
                        );
                      })}
                    </Select>
                  </Stack>
                  {currentUser.role === 'project-manager' && (
                    <Button
                      onClick={handleClickAddProject}
                      borderRadius="5px"
                      border="1px solid transparent"
                      background="#C6F6D5"
                      padding={{ base: '10px', md: '15px' }}
                      marginInline="auto!important"
                      minWidth="150px"
                      marginTop="20px"
                      fontSize={{ base: '10px', md: '12px' }}
                    >
                      Add employee to your project
                    </Button>
                  )}
                </Stack>
              </>
            )}
          </Stack>
        ) : (
          <Stack>
            <Text fontSize={{ b: '18px', md: '22px' }} mb="5px">
              This user is currently working on:
            </Text>
            <Flex
              padding="5px"
              paddingInline="12px"
              borderRadius="5px"
              justifyContent="space-between"
              backgroundColor="rgb(255 23 68 / 50%)"
              alignItems="center"
            >
              <Text wordBreak="break-word">{result?.project?.project}</Text>
              {currentUser.role === 'project-manager' &&
                projectsArr.includes(result?.project?.project) && (
                  <Button
                    onClick={handleClickRemoveProject}
                    borderRadius="50%"
                    border="1px solid #ff1744"
                  >
                    x
                  </Button>
                )}
            </Flex>
            {currentUser.role === 'project-manager' &&
              resultProject?.project[0]?.project === result?.project?.project && (
                <Button disabled={true} fontSize={{ base: '10px', md: '17px' }}>
                  Remove employee from your project
                </Button>
              )}
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default EmployeeProjects;
