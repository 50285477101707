/* eslint-disable no-unused-vars */
import clsx from 'clsx';
import { useState } from 'react';
import GoogleButton from 'react-google-button';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { API_URL } from 'config/config.js';
import { useAuthContext } from 'context/AuthContext.jsx';

// Import styles:
import './Login.scss';

const Login = () => {
  const { handleLogin } = useAuthContext();
  const navigate = useNavigate();

  const redirectToGoogleSSO = async () => {
    let timer = null;
    const googleLoginURL = API_URL + `/google`;
    open('https://api.employee.mom.rs/google');

    /*if (newWindow) {
      timer = setInterval(() => {
        if (newWindow.closed) {
          console.log('you are done with google SSO');
          handleLogin();
          clearInterval(timer);
        }
      }, 1000);
    }*/
  };

  return (
    <section className="login">
      <div className="login__content">
        <h2 className="login__title">Employee Info - Login</h2>
        <GoogleButton type="dark" onClick={redirectToGoogleSSO} />
      </div>
    </section>
  );
};
export default Login;
