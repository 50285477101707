import axiosInstance from './http';

// u selectedValue je country, city, seniority
// u t je Srbija, Madjarska, junior, PHP...

const manageData = {
  changeData: async (selectedValue, stariItem, noviItem) => {
    try {
      const key = selectedValue;
      const m = stariItem.toLowerCase().replace(/ /g, '-');

      console.log(selectedValue, m, 'ffafafaafafafaffa', noviItem);
      console.log(key);
      const response = await axiosInstance.put(`${selectedValue}/${m}`, {
        [key]: noviItem,
      });
      console.log(response);
      return response.data;
    } catch (err) {
      console.error(`${err.message}, 💥🤯`);
    }
  },
  deleteData: async (t, selectedValue) => {
    try {
      console.log(selectedValue, t);
      const m = t.toLowerCase().replace(/ /g, '-');
      console.log(m);
      const response = await axiosInstance.delete(`/${selectedValue}/${m}`);
      console.log(response);
      return response.data;
    } catch (err) {
      console.error(`${err.message}, 💥🤯`);
    }
  },
  addData: async (t, selectedValue) => {
    try {
      // const obj = {};
      const key = selectedValue;
      const response = await axiosInstance.post(`${selectedValue}`, {
        [key]: t,
      });
      return response;
    } catch (err) {
      console.error(`${err.message}`);
    }
  },
  addCity: async (country, city) => {
    try {
      // const key = city;
      const response = await axiosInstance.post(`/city`, {
        country: country,
        city: city,
      });
      return response;
    } catch (err) {
      console.error(`${err.message}`);
    }
  },
  addEmployee: async (name, lastName, email) => {
    try {
      const response = await axiosInstance.post(`/employee`, {
        name: name,
        lastName: lastName,
        email: email,
      });
      return response;
    } catch (err) {
      console.error(`${err.message}`);
    }
  },
  deleteEmployee: async (email, selectedValue) => {
    try {
      // const mail = t.toLowerCase();
      const sliceEmail = email.slice(0, email.indexOf('@'));
      console.log(sliceEmail);
      const response = await axiosInstance.delete(`/${selectedValue}`);
      console.log(response);
      console.log(sliceEmail);
      return response.data;
    } catch (err) {
      console.error(`${err.message}, 💥🤯`);
    }
  },
  getUser: async () => {
    try {
      const response = await axiosInstance.get(`/user`);
      console.log(response);
      return response.data;
    } catch (err) {
      console.error(`${err.message}, 💥🤯`);
    }
  },
  addUser: async (email, role, name, picture) => {
    try {
      const response = await axiosInstance.post(`/user`, {
        email: email,
        name: name,
        role: role,
        picture: picture,
      });
      console.log(
        response,
        'fsffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffeeeeeeee'
      );
      // if (response.status >= 200) {
      //   console.log('nesto');
      //   manageData.getUser();
      // }
      return response.data;
    } catch (err) {
      console.error(`${err.message}, 💥🤯`);
    }
  },
  deleteUser: async (id) => {
    try {
      const response = await axiosInstance.delete(`/user/${id}`);
      console.log(response);
      return response.data;
    } catch (err) {
      console.error(`${err.message}, 💥🤯`);
    }
  },
  editUser: async (id, role) => {
    try {
      const response = await axiosInstance.put(`/user/${id}`, {
        role: role,
      });
      console.log(response);
      return response.data;
    } catch (err) {
      console.error(`${err.message}, 💥🤯`);
    }
  },
};

export default manageData;
