/** @jsxImportSource @emotion/react */
import {
  Heading,
  Box,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage,
  Button,
  Select,
  useTheme,
  Checkbox,
  CheckboxGroup,
  Stack,
  useCheckboxGroup,
  useToast,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import {
  addEmployee,
  fetchCities,
  fetchCountries,
  fetchTechnologies,
  fetchSeniorities,
  getEmployee,
  editEmployee,
} from 'services/employees';
import { isEmail } from 'utils/validation';

const boxCss = css({
  maxWidth: '400px',
  margin: 'auto',
  padding: '20px',
  '@media (max-width: 420px)': {
    padding: '10px',
    width: '98%',
  },
  '& label': {
    fontSize: '20px',
    marginTop: '4px',
  },
  '& input': {
    fontSize: '20px',
  },
});

function EmployeeForm({ setAddingEmployee, setEditingEmployee, type, employeeEmail, setAdded }) {
  const theme = useTheme();
  const toast = useToast();

  const [inputName, setInputName] = useState(type === 'editing' ? 'Loading...' : '');
  const [inputLastName, setInputLastName] = useState(type === 'editing' ? 'Loading...' : '');
  const [inputEmail, setInputEmail] = useState(type === 'editing' ? 'Loading...' : '');
  const [countrySelected, setCountrySelected] = useState('');
  const [citySelected, setCitySelected] = useState('');
  const [senioritySelected, setSenioritySelected] = useState('');
  const [countriesAvail, setCountriesAvail] = useState([]);
  const [technologiesAvail, setTechnologiesAvail] = useState([]);
  const [citiesAvail, setCitiesAvail] = useState([]);
  const [senioritiesAvail, setSenioritesAvail] = useState([]);

  const { value: technologiesSelected, getCheckboxProps } = useCheckboxGroup();

  const queryClient = useQueryClient();

  const mutationEmployee = useMutation(
    ([type, newEmployee]) => {
      if (type === 'editing') {
        const employeeEmailPrefix = employeeEmail.split('@')[0];
        return editEmployee(newEmployee, employeeEmailPrefix);
      } else {
        return addEmployee(newEmployee);
      }
    },
    {
      onMutate: async ([type, newEmployee]) => {
        console.log('on mutate newEMployee', type, newEmployee);
        await queryClient.cancelQueries('employeesReactQueryCachedData');
        const previousData = queryClient.getQueryData('employeesReactQueryCachedData');
        if (type === 'editing') {
          const newEditedEmployeesData = previousData.map((oldEmployee) =>
            oldEmployee.email === newEmployee.email ? newEmployee : oldEmployee
          );
          console.log('new employee edited data', newEditedEmployeesData);
          queryClient.setQueryData('employeesReactQueryCachedData', newEditedEmployeesData);
        } else {
          queryClient.setQueryData('employeesReactQueryCachedData', (old) => [...old, newEmployee]);
        }
        return { previousData };
      },
      onError: (err, newEmployee, context) => {
        queryClient.setQueryData('employeesReactQueryCachedData', context.previousData);
      },
      onSettled: () => {
        queryClient.invalidateQueries('employeesReactQueryCachedData', {
          refetchInactive: true,
        });
      },
    }
  );

  const handleNameInputChange = (e) => setInputName(e.target.value);
  const handleLastNameInputChange = (e) => setInputLastName(e.target.value);
  const handleEmailInputChange = (e) => setInputEmail(e.target.value);
  const handleSelectChange = (e, selectType) => {
    switch (selectType) {
      case 'country':
        setCountrySelected(e.target.value);
        break;
      case 'city':
        setCitySelected(e.target.value);
        break;
      case 'seniority':
        setSenioritySelected(e.target.value);
        break;
    }
  };

  const isEmailError = inputEmail === '' || !isEmail(inputEmail);

  // Handle Employee Submit:
  const handleEmployeeSubbmission = async (e) => {
    e.preventDefault();
    const employeeObj = {
      name: inputName,
      lastName: inputLastName,
      email: inputEmail,
      country: countrySelected,
      city: citySelected,
      seniority: senioritySelected,
      technologies: technologiesSelected,
    };

    const employeeResponse = await mutationEmployee.mutateAsync([type, employeeObj]);

    console.log('employeeResponse: ', employeeResponse, typeof employeeResponse?.status);
    // if we successfully added employee show success toast otherwise error toast!
    const customToastProps = {
      duration: 3000,
      isClosable: true,
      position: 'bottom-left',
      containerStyle: {
        margin: '20px',
      },
    };

    if (employeeResponse?.status === 200) {
      toast({
        title: `Employee ${type === 'editing' ? 'edited' : 'added'}!`,
        description: `We've successfully ${
          type === 'editing' ? 'edited' : 'added'
        } employee for you.`,
        status: 'success',
        ...customToastProps,
      });
    } else {
      toast({
        title: `Error ${type === 'editing' ? 'editing' : 'adding'} employee!`,
        description: `We've not ${type === 'editing' ? 'edited' : 'added'} employee.`,
        status: 'error',
        ...customToastProps,
      });
    }

    if (type === 'editing') {
      setEditingEmployee(false);
    } else {
      setAddingEmployee(false);
    }

    setAdded((prevState) => !prevState);
  };

  const getAvailData = async () => {
    // maybe move all this to CONTEXT API and have it there so we dont have every time to fetch this data!!!
    // or we could use react query?!
    const [countries, technologies, cities, seniorities] = await Promise.all([
      fetchCountries(),
      fetchTechnologies(),
      fetchCities(),
      fetchSeniorities(),
    ]);
    setCountriesAvail(countries);
    setTechnologiesAvail(technologies);
    setCitiesAvail(cities);
    setSenioritesAvail(seniorities);
  };

  const getEmployeeData = async () => {
    const employeeEmailPrefix = employeeEmail.split('@')[0];
    const getEmployeeData = await getEmployee(employeeEmailPrefix);
    const technologiesArr = getEmployeeData.technologies.map((techArr) => techArr.technology);
    technologiesArr.forEach((tech) => {
      technologiesSelected.push(tech);
    });
    setInputName(getEmployeeData.name);
    setInputLastName(getEmployeeData.lastName);
    setInputEmail(getEmployeeData.email);
    setCountrySelected(getEmployeeData.countryId || '');
    setCitySelected(getEmployeeData.cityId || '');
    setSenioritySelected(getEmployeeData.seniorityId || '');
  };

  useEffect(() => {
    getAvailData();
  }, []);

  useEffect(() => {
    if (type === 'editing') {
      technologiesSelected.length = 0;
      getEmployeeData();
    }
  }, [employeeEmail]);

  return (
    <Box
      css={css(boxCss, {
        '& option': {
          color: 'white',
          fontSize: '20px',
          backgroundColor: `${theme.colors.color.mainSectionBackgroundColor} !important`,
        },
      })}
    >
      <Heading style={{ textAlign: 'center' }} size="2xl">
        {type === 'editing' ? 'Edit Employee' : 'Add Employee'}
      </Heading>
      <form onSubmit={handleEmployeeSubbmission} style={{ marginTop: '40px' }}>
        <FormControl isRequired>
          <FormLabel htmlFor="first-name" lineHeight={{ base: '15px', md: '22px' }}>
            First name
          </FormLabel>
          <Input
            size="lg"
            id="first-name"
            placeholder="First name"
            value={inputName}
            onChange={handleNameInputChange}
          />
          <FormHelperText>Enter the first name of employee.</FormHelperText>
        </FormControl>
        <FormControl isRequired>
          <FormLabel htmlFor="last-name" lineHeight={{ base: '15px', md: '22px' }}>
            Last name
          </FormLabel>
          <Input
            size="lg"
            id="last-name"
            placeholder="Last name"
            value={inputLastName}
            onChange={handleLastNameInputChange}
          />
          <FormHelperText>Enter the last name of employee.</FormHelperText>
        </FormControl>
        <FormControl
          isInvalid={isEmailError}
          isRequired
          isReadOnly={type === 'editing' ? true : false}
        >
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            size="lg"
            id="email"
            type="email"
            value={inputEmail}
            onChange={handleEmailInputChange}
          />
          {!isEmailError ? (
            <FormHelperText>Enter the email of employee.</FormHelperText>
          ) : (
            <FormErrorMessage>Email is not valid!</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isRequired>
          <FormLabel htmlFor="country">From</FormLabel>
          <Select
            size="lg"
            id="country"
            placeholder="Select country"
            onChange={(e) => handleSelectChange(e, 'country')}
            value={countrySelected}
          >
            {countriesAvail.map((country, index) => {
              return (
                <option value={country?.country} key={index}>
                  {country?.country}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel htmlFor="city">City</FormLabel>
          <Select
            size="lg"
            id="city"
            placeholder="Select city"
            onChange={(e) => handleSelectChange(e, 'city')}
            value={citySelected}
          >
            {citiesAvail.map((city, index) => {
              return (
                <option value={city?.city} key={index}>
                  {city?.city}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel htmlFor="seniority">Seniority</FormLabel>
          <Select
            size="lg"
            id="seniority"
            placeholder="Select seniority"
            onChange={(e) => handleSelectChange(e, 'seniority')}
            value={senioritySelected}
          >
            {senioritiesAvail.map((seniority, index) => {
              return (
                <option value={seniority?.seniority} key={index}>
                  {seniority?.seniority}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <FormLabel htmlFor="technology">Technologies:</FormLabel>
        <CheckboxGroup colorScheme="green" defaultValue={technologiesSelected}>
          <Stack
            spacing={[1, 5]}
            direction={['column', 'row']}
            flexWrap="wrap"
            justifyContent={'center'}
          >
            {technologiesAvail.map((tech, index) => {
              return (
                <Checkbox
                  size="lg"
                  key={index}
                  value={tech.technology}
                  css={css({
                    '.chakra-checkbox__label': {
                      fontSize: '16px',
                    },
                  })}
                  {...getCheckboxProps({ value: tech.technology })}
                >
                  {tech.technology}
                </Checkbox>
              );
            })}
          </Stack>
        </CheckboxGroup>

        <Button size="lg" d="block" m="auto" mt={4} colorScheme="teal" type="submit">
          {type === 'editing' ? 'Edit Employee' : 'Add Employee'}
        </Button>
      </form>
    </Box>
  );
}

export default EmployeeForm;
